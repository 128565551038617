<template>
    <ModalGeneral :title="title" title-icon="fas fa-exclamation-circle" size="modal-md" :showModal="showModal" :hideModal="hideModal" v-if="showModal">
        <div slot="modal-body">
            <div class="ml-2">{{ this.text }}</div>
            <div class="text-center mt-5 mb-3">
                <router-link :to="{ name: 'App.Packages', params: {}}" class="btn btn-outline-primary">
                    <i class="fas fa-chevron-circle-up"></i>
                    Upgrade Account
                </router-link>
            </div>
        </div>
    </ModalGeneral>
</template>

<script>
import ModalGeneral from "@/components/app/dialog/ModalGeneral";
export default {
    name: "FeatureNotAvailableModal",
    components: {ModalGeneral},
    data: function() {
        return {
        }
    },
    props: {
        title:{
            type: String,
            default: ()=> ''
        },
        text:{
            type: String,
            default: ()=> ''
        },
        showModal:{
            type: Boolean,
            default: ()=> false
        },
        hideModal: {
            type: Function,
            default:() => {}
        },
    },
    computed:{

    },
    methods:{

    },
    mounted() {
    }
}
</script>

<style scoped>

</style>