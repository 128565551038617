<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content pl-2 pr-2">
            <CustomDataTable v-bind:tableDataUrl="tableDataUrl" v-bind:columns="columns" v-bind:searchFilters="searchFilters" v-bind:additionalButtons="additionalButtons" ref="mainDataTable"></CustomDataTable>
        </section>
        <!-- /.content -->
        <FeatureNotAvailableModal v-bind:title="featureNotAvailableModal.title" v-bind:text="featureNotAvailableModal.text" v-bind:show-modal="featureNotAvailableModal.show" v-bind:hide-modal="hideFeatureNotAvailableModal"></FeatureNotAvailableModal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import CustomDataTable from "../../../components/app/table/CustomDataTable";
    import {getPermissionByKey} from "@/helpers/userHelper";
    import CustomDataTableDropDownButton from "@/components/app/table/CustomDataTableDropDownButton";
    import {UserService} from "@/services/user-service";
    import FeatureNotAvailableModal from "@/components/app/package/FeatureNotAvailableModal";

    let userService = new UserService();
    export default {
        name: 'Users_view',
        data: function(){
            return {
                pageName: 'Users',
                segments: [
                    { link: false, text: 'Users' },
                ],
                user: {},
                tableDataUrl: this.$globalSettings.api.endpoints.user.usersDatatable,
                columns: [
                    {label: 'ID', name: 'id', orderable: true, style: 'width: 60px'},
                    {label: 'Role', name: 'role_name', orderable: true},
                    {label: 'Name', name: 'name', orderable: true},
                    {label: 'Image', name: 'image', orderable: false, transform: ({data, name}) => data[name]? `<img src="${this.$apiBaseURL + '/' + data[name]}" class="img-sm" />`: '' },
                    {label: 'Email', name: 'email', orderable: true},
                    {
                        label: '',
                        style: 'width: 60px',
                        component: CustomDataTableDropDownButton,
                        meta: {
                            items: [
                                {
                                    label: 'Edit',
                                    showLabel: true,
                                    iconClass: 'fas fa-edit',
                                    event: "click",
                                    handler: this.edit,
                                    dataPermissionConditions: [{column: 'editable', val: 1, permission: this.$globalSettings.permissions.user.user_edit}],
                                    conditions: [],
                                },
                                {
                                    label: 'Permissions',
                                    showLabel: true,
                                    iconClass: 'fas fa-user-shield',
                                    event: "click",
                                    handler: this.permissions,
                                    dataPermissionConditions: [{column: 'editable', val: 1, permission: this.$globalSettings.permissions.user.user_permissions}],
                                    conditions: [],
                                },
                                {
                                    label: 'Delete',
                                    showLabel: true,
                                    iconClass: 'fas fa-trash',
                                    event: "click",
                                    handler: this.delete,
                                    dataPermissionConditions: [{column: 'editable', val: 1, permission: this.$globalSettings.permissions.user.user_delete}],
                                    conditions: [],
                                },
                            ]
                        }
                    },
                ],
                searchFilters:{
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc'
                },
                additionalButtons: [],
                addUserEnabled: false,

                featureNotAvailableModal:{
                    show: false,
                    title: '',
                    text: '',
                },
            }
        },
        props: {},
        components: {
            FeatureNotAvailableModal,
            BreadCrumb, CustomDataTable
        },
        computed: {

        },
        methods: {
            hideFeatureNotAvailableModal: function (){
                this.featureNotAvailableModal.show = false;
            },
            async add() {
                if (this.addUserEnabled) {
                    await this.$router.push({name: 'App.User', params: {id: 0}});
                } else {
                    this.featureNotAvailableModal.show = true;
                    this.featureNotAvailableModal.title = 'Action unavailable!';
                    this.featureNotAvailableModal.text = 'Your subscription package does not allow to add any more users.';
                }
            },
            async edit(data) {
                await this.$router.push({name: 'App.User', params: {id: data.id}});
            },
            async permissions(data) {
                await this.$router.push({name: 'App.User.Permissions', params: {id: data.id}});
            },
            async delete(data) {
                if (confirm("Are you sure that you want to delete this user?")) {
                    let loader = this.$loading.show();
                    let response = await userService.delete(data.id);
                    if (response.isSuccess) {
                        await this.$refs.mainDataTable.loadTable();
                        await this.checkPackageKeyPermission();
                    }else{
                        Vue.$toast.open({message: response.message, type: 'error'});
                    }
                    loader.hide();
                }
            },
            checkPackageKeyPermission: async function(){
                let loader = this.$loading.show();
                let response = await userService.checkPackageKeyPermission({
                    key: 'users'
                });
                if (response.isSuccess) {
                    this.addUserEnabled = true;
                }else{
                    this.addUserEnabled = false;
                }
                loader.hide();
            },
        },
        async created () {
            if (getPermissionByKey(this.$globalSettings.permissions.user.user_create)) {
                this.additionalButtons.push({ label: 'Add', buttonClass: 'btn btn-outline-secondary btn-sm float-right', labelIconClass: 'fas fa-plus', click: this.add });
            }
            await this.checkPackageKeyPermission();

        }
    }
</script>

<style scoped>

</style>
